import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentConfirmation = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/menu");
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.icon}>
          <span role="img" aria-label="success">
            ✅
          </span>
        </div>
        <h1 style={styles.title}>Оплата успешна!</h1>
        <p style={styles.text}>
          Спасибо! Ваша оплата успешно обработана.
        </p>
        <button style={styles.button} onClick={handleGoBack}>
          Вернуться в меню
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "var(--background-color)",
  },
  card: {
    width: "400px",
    padding: "30px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  icon: {
    fontSize: "50px",
    color: "#4caf50",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#686868",
  },
  text: {
    fontSize: "16px",
    marginBottom: "20px",
    color: "#686868",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "rgb(255 64 129)",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
};

export default PaymentConfirmation;
