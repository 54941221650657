import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";

const Failtrig = () => {
  const navigate = useNavigate();
  const { authenticateTelegramUser, token } = useContext(AuthContext);
  const [isTelegram, setIsTelegram] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Проверяем, находится ли пользователь в Telegram Web App
    const telegramWebApp = typeof window.Telegram !== "undefined" && typeof window.Telegram.WebApp !== "undefined";
    const initData = window.Telegram?.WebApp?.initData;

    if (telegramWebApp && initData) {
      console.log("Пользователь из Telegram Web App. initData:", initData);
      setIsTelegram(true);
      authenticateTelegramUser();
    } else {
      console.log("Пользователь не из Telegram или initData отсутствует. Перенаправляем на /menu.");
      setIsTelegram(false);
      navigate("/fail");
    }
    setLoading(false);
  }, [authenticateTelegramUser, navigate]);

  useEffect(() => {
    // Если токен получен, перенаправляем на /search
    if (token) {
      navigate("/fail");
    }
  }, [token, navigate]);

  if (loading) {
    return <p>Загрузка...</p>;
  }

  return (
    <div>
      {isTelegram ? (
        <>
          <h1>Telegram Web App</h1>
          {token ? (
            <p>Токен получен. Переход на поиск...</p>
          ) : (
            <p>Получение токена...</p>
          )}
        </>
      ) : (
        <h1>Вы перенаправлены на страницу меню...</h1>
      )}
    </div>
  );
};

export default Failtrig;
