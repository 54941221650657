import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import s from '../styles/CreateProfile.module.css'; // Подключаем файл стилей
import { AuthContext } from '../AuthContext';
import ImageUploader from './Foto'; // Импортируем компонент для загрузки и обрезки фото
import { useTranslation } from 'react-i18next';

// Компонент для полосы прогресса
function ProgressBar({ progress }) {
  const progressStyle = {
    width: `${progress}%`,
    backgroundColor: '#ff4081',
    marginTop: '30px',
    height: '4px',
  };

  return (
    <div style={{ width: '100%', backgroundColor: '#ddd' }}>
      <div style={progressStyle}></div>
    </div>
  );
}

function CreateProfile() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    city: '',
    age: '',
    gender: '',
    bio: '',
    photos: Array(6).fill(null), // Массив для 6 фото
  });
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null); // Индекс текущего фото для обрезки
  // const [croppedBlob, setCroppedBlob] = useState(null); // Для хранения обрезанного изображения
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { setCurrentUserProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [location, setLocation] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [city, setCity] = useState('');

  // Храним текущий шаг регистрации (1 — начальный, всего 5 шагов)
  const [step, setStep] = useState(1);

  const totalSteps = 4; // Всего шагов регистрации
  const progress = (step / totalSteps) * 100; // Прогресс в процентах

  const [citycheck, setCitycheck] = useState('');  // Поле для ввода города
  const [serverCityData, setServerCityData] = useState('');  // Данные с сервера о городе
  const [errorcitycheck, setErrorcitycheck] = useState(null);  // Для обработки ошибок
  const [validationError, setValidationError] = useState('');

  

  // Проверка активности кнопки Next
  const isNextDisabled = () => {
    switch (step) {
      case 1:
        return formData.name.trim() === '' || formData.gender === '' || formData.age.trim() === '' ||  parseInt(formData.age, 10) < 18 ; // Шаг 1: поле Name должно быть заполнено
      case 2:
        return formData.city.trim() === ''; // Шаг 2: поля City и Age должны быть заполнены
      // case 3:
      //   return formData.bio.trim() === ''; // Шаг 3: Gender и Bio должны быть заполнены
      case 3:
        return !formData.photos.some(photo => photo !== null); // Шаг 4: Должно быть хотя бы одно загруженное фото
      default:
        return false;
    }
  };

  // // Переход на следующий шаг
  // const nextStep = () => {
  //   if (step < totalSteps) {
  //     setStep(step + 1);
  //   }
  // };

  // Переход на следующий шаг с проверкой
  const nextStep = () => {
    if (isNextDisabled()) {
      // Устанавливаем сообщение об ошибке, если условия не выполнены
      if (step === 1 && parseInt(formData.age, 10) < 18) {
        setValidationError(t('Age must be at least 18 years.'));
      } else if (step === 3 && !formData.photos.some(photo => photo !== null)) {
        // Проверка наличия хотя бы одного фото на шаге 3
        setValidationError(t('Please add at least one photo.'));
      } else {
        setValidationError(t('Please fill in all required fields.'));
      }
    } else {
      setValidationError(''); // Сбрасываем ошибку при успешном переходе
      if (step < totalSteps) {
        setStep(step + 1);
      }
    }
  };

  // Переход на предыдущий шаг
  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  // Обработка изменений в текстовых полях
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Отправка формы
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    

    const formDataObj = new FormData();
    formDataObj.append('name', formData.name);
    formDataObj.append('city', formData.city);
    formDataObj.append('age', formData.age);
    formDataObj.append('gender', formData.gender);
    formDataObj.append('bio', formData.bio);
    formDataObj.append('latitude', formData.latitude);  // Используем formData.latitude
    formDataObj.append('longitude', formData.longitude);  // Используем formData.longitude
    
  

    formData.photos.forEach((photo, index) => {
      if (photo) {
        const fileName = `photo_${index + 1}.jpg`; // Задаём корректное имя файла
        const file = new File([photo], fileName, { type: 'image/jpeg' }); // Создаём объект File с именем
        formDataObj.append('photo', file); // Добавляем файл в FormData
      }
      
    });

     // Определение противоположного пола
    let oppositeGender = '';
    if (formData.gender === 'Male') {
      oppositeGender = 'Female';
    } else if (formData.gender === 'Female') {
      oppositeGender = 'Male';
    }

    // Сохранение противоположного выбора пола в localStorage
    localStorage.setItem('selectedGender', oppositeGender);
    
    fetch('/api/create_profile', {
      method: 'POST',
      body: formDataObj,
      headers: {
        ...(localStorage.getItem("jwtToken") && { 
          "Authorization": localStorage.getItem("jwtToken") 
        }),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setSuccess(true);
          setCurrentUserProfile(true);
          
          // // Показать сообщение благодарности
          // setShowThankYou(true);

          // Через 3 секунды выполнить навигацию
          setLoading(false);
          navigate('/training');
         
        }
      })
      .catch((error) => {
        setError('Error creating profile');
        console.error('Error creating profile:', error);
      })
      // Сбрасываем флаг после завершения запроса// Сбрасываем флаг после завершения запроса
      
  };


  // Функция для удаления фото по индексу
  const handlePhotoDelete = (index) => {
    const updatedPhotos = [...formData.photos];
    updatedPhotos[index] = null; // Удаляем фото (ставим null)
    setFormData({ ...formData, photos: updatedPhotos }); // Обновляем состояние
  };
  

  // Обработка выбора и обрезки фото
  const handlePhotoSelect = (index) => {
    setSelectedPhotoIndex(index);
  };

  const handlePhotoUpload = (blob) => {
    const updatedPhotos = [...formData.photos];
    updatedPhotos[selectedPhotoIndex] = blob;
    setFormData({ ...formData, photos: updatedPhotos });
    setSelectedPhotoIndex(null);
  };


// Обработчик изменения поля "Город"
const handleCitycheckChange = (e) => {
  setCitycheck(e.target.value);
};

// Асинхронная функция для отправки города на сервер
const handleCitycheckSubmit = async () => {
  // Проверяем, что поле "Город" не пустое
  if (!citycheck) {
    alert("Поле город обязательно для заполнения!");
    return;
  }
  try {
    const response = await fetch('/api/citycheck', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ citycheck }),  // Отправляем только город
    });

    if (!response.ok) {
      throw new Error('Error fetching city data');
    }

    const data = await response.json();
    setServerCityData(data.city);
    setCity(data.city);  // Обновляем данные города
    const [latitude, longitude] = data.coords;
    setLocation({ latitude, longitude });
    setStep(3);  // Переходим на следующий шаг после получения данных
  } catch (error) {
    setErrorcitycheck(t('Failed to fetch city data'));
  }
};



  // Функция для получения геолокации
  const requestGeolocation = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          getCityFromServer(latitude, longitude); // Выполняем запрос к серверу
        },
        (error) => {
          setErrorMessage(t('locationDenied'));
          console.error(error);
        }
      );
    } else {
      setErrorMessage('Geolocation is not supported by your browser');
    }
  }, [t]);

  
  


  // Функция для отправки данных геолокации на сервер
  const getCityFromServer = async (latitude, longitude) => {
    try {
      const response = await fetch('/api/get_city', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ latitude, longitude }), // Отправляем координаты на сервер
      });

      if (!response.ok) {
        throw new Error('Failed to get city');
      }

      const data = await response.json();
      setCity(data.city);  // Устанавливаем название города в состояние
    } catch (error) {
      setErrorMessage('Failed to retrieve city');
      console.error(error);
    }
  };

  useEffect(() => {
    if (location && city) {
      setFormData((prevData) => ({
        ...prevData,
        latitude: location.latitude,
        longitude: location.longitude,
        city: city
      }));
      setStep(3);
    }
  }, [location, city, setStep]);


  const handleFormKeyDown = (e) => {
    if (e.key === 'Enter' && step !== totalSteps) {
      e.preventDefault(); // Отключаем отправку формы на промежуточных шагах
    }
  };



  return (
    <div>
      <h1 className='all_str_h1'>{t("Create Profile")}</h1>
      
    {loading && <p className='loading'>{t("Loading")}...</p>}
    
    {!loading && <div>
      
      
      <ProgressBar progress={progress} />
      {step !== totalSteps && step> 1 && selectedPhotoIndex === null && (
                  
                  <div >
                <button className={s.btn_prev} onClick={prevStep} disabled={step === 1}>
                &lt;
                </button>
                          
                </div>
                )}

      {success && <p>{t("create_suches")}</p>}
      {error && <p>{error}</p>}
      {validationError && <p className={s.validationError}>{validationError}</p>} {/* Вывод ошибки */}
      
      <div className={s.edit_container}>
        <div className={s.create_edit_form}>
          <form onSubmit={handleSubmit} onKeyDown={handleFormKeyDown}>
            {/* Шаг 1: Ввод имени */}
            {step === 1 && (
              <>
              <div className={s.create_form_group}>
                <label htmlFor="name">{t("Name")}:</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={s.create_form_group}>
                  <label htmlFor="age">{t("Age")}:</label>
                  <input
                    id="age"
                    type="number"
                    name="age"
                    value={formData.age}
                    onChange={handleChange}
                    required
                  />
                </div>
              <div className={s.create_form_group}>
              <label htmlFor="gender">{t("Gender_cr")}:</label>
              <select
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
              >
                <option value="">{t("Select Gender")}</option>
                <option value="Male">{t("Male")}</option>
                <option value="Female">{t("Female")}</option>
              </select>
            </div>
            

              
            </>
              
            )}

            {/* Шаг 2: Ввод города и возраста */}
            {step === 2 && (
              <>
              
              {!errorMessage&& (
              <>
                <div className={s.geo}>
                  <h2>{t("Why We Need Your Location")}</h2>
                  <p>
                      {t("why_loca")}
                  </p>
                  <div className={s.btn_edit}>
                  <button type="button" onClick={requestGeolocation}>
                    {t("Location")}
                  </button>
                  </div>
                </div>
                </>
                  )}
                <div className={s.geo}>
                  
                        {errorMessage && (
                          <>
                          {/* <p>{errorMessage}</p> */}
                          
                            <h2>{t("location danied in browser")}</h2>
                            <div className={s.create_form_group}>
                            <label className={s.label_city}>
                              Город:
                              </label>
                              <input
                                type="text"
                                value={citycheck}
                                onChange={handleCitycheckChange}
                                required
                              />
                            
                            <div className={s.btn_edit_city}>
                            <button type="button" onClick={handleCitycheckSubmit}>Готово</button>
                            </div>
                            

                            {/* Показываем данные с сервера, если они есть */}
                            {serverCityData && (
                              <div>
                                <h3>Информация о городе:</h3>
                                <p>{serverCityData}</p>
                                {/* <button type="button" onClick={nextStep}>Далее</button> */}
                              </div>
                            )}
                          </div>
                          {errorcitycheck && <p>{errorcitycheck}</p>}
                      
                          </>
                      )}


                        {/* {location && <p>Latitude: {location.latitude}, Longitude: {location.longitude}</p>} */}
                        {city && <p>City: {city}</p>}
                  </div>  
                  


              {/* Поля формы для отправки координат и города */}
              <div className={s.create_form_group}>
                    <input
                      type="hidden"
                      name="latitude"
                      value={formData.latitude || ''}
                    />
                    <input
                      type="hidden"
                      name="longitude"
                      value={formData.longitude || ''}
                    />
                    <div className={s.create_form_group}>
                      {/* <label htmlFor="city">City:</label> */}
                      <input
                        id="city"
                        type="hidden"
                        name="city"
                        value={formData.city || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                
              </>
            )}

            {/* Шаг 3: Ввод биографии и выбор пола */}
            {/* {step === 3 && (
              <>
                <div className={s.create_form_group}>
                  <label htmlFor="bio">Bio:</label>
                  <textarea
                    id="bio"
                    name="bio"
                    value={formData.bio}
                    onChange={handleChange}
                    rows={3}
                  />
                </div>
              </>
            )} */}

            {/* Шаг 3: Загрузка фото */}
            {step === 3 && (

            <>
                {/* Показываем карточки с фото, только если фотография не выбрана для обрезки */}
                {selectedPhotoIndex === null && (
                  <div className={s.create_photo_grid}>
                    {formData.photos.map((photo, index) => (
                      <div key={index} className={s.photo_card} >
                        {photo ? (
                          <>
                          <img src={URL.createObjectURL(photo)} alt={`foto ${index + 1}`} />
                                          <button 
                            className={s.create_batton_x} 
                            onClick={() => handlePhotoDelete(index)}
                          >
                            
                          </button>
                          </>
                        ) : (
                          <div className={s.create_placeholder} onClick={() => handlePhotoSelect(index)}></div>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {/* Показываем компонент для обрезки, если выбрана карточка */}
                {selectedPhotoIndex !== null && (
                  <ImageUploader setPhotoBlob={handlePhotoUpload} />
                )}
              </>
            )}

            {/* Кнопки управления шагами */}
            <div className={s.btn_edit_container}>
            <div className={s.btn_edit}>
              {step === totalSteps && (
                  
                  <div >
                <button className={s.btn_prev2} onClick={prevStep} disabled={step === 1}>
                &lt;
                </button>
                          
                </div>
                )}
                {step === totalSteps && (
                  
                  <button type="submit" >{t("Create")}</button>)}
                  
                  </div> 
              </div>
          </form>
        </div>
      </div>

      {step !== totalSteps && selectedPhotoIndex === null && step !== 2&& (
                  <div className={s.btn_edit_container}>
                    <div className={s.btn_edit}>
                  {/* <button onClick={nextStep} disabled={isNextDisabled() || step === totalSteps}>
                    {t("Next")}
                  </button> */}
                  <button onClick={nextStep} disabled={step === totalSteps}>
                    {t("Next")}
                  </button>
                  </div>
        </div>
        )}
        
    </div>}
    </div>
  );
}

export default CreateProfile;
